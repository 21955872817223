<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :editTitle="'巡检地图'"
    >
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/meritPatrolMap",
      searchQuery: { title: "" },
      searchControl: {
        firstSoltSpan: [5, 5, 5, 5],
        secondSoltSpan: [0, 0, 0, 0],
        thirdSoltSpan: [17, 17, 17, 17],
      },
      props: [
        {
          label: "楼层",
          prop: "floor",
          width: 200,
          align: "center",
        },

        {
          label: "地址",
          prop: "detailLocation",
          align: "center",
        },
        {
          label: "图片",
          prop: "mapImg",
          align: "center",
          type: "image",
        },

        {
          label: "创建时间",
          prop: "createTime",
          align: "center",
        },
      ],
      formProps: [
        {
          label: "楼层",
          type: "input",
          prop: "floor",
        },

        {
          label: "地址",
          type: "input",
          prop: "detailLocation",
        },
        {
          label: "图片",
          type: "upload",
          prop: "mapImg",
        },
      ],
    };
  },
  methods: {
    // resetSearch() {
    //   this.searchQuery.title = this.title;
    // },
  },
};
</script>

<style lang="scss" scoped></style>
